import React, {FC, useEffect, useCallback} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useRouter} from 'next/router';

import {styles, Loader} from '@zoefin/design-system';

import pageStyles from './styles.module.scss';

import {withPageAuthSkipped} from '../../utils/auth0Utils';

const Login: FC = () => {
  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    buildAuthorizeUrl,
  } = useAuth0();
  const {query} = useRouter();

  const sendToUniversalLogin = useCallback(async () => {
    const authorizeUrl = await buildAuthorizeUrl();
    const url = new URL(authorizeUrl);
    const returnTo = query.returnTo?.toString() || undefined;

    loginWithRedirect({
      redirectUri: url.searchParams.get('redirect_uri'),
      appState: {returnTo},
    });
  }, [query, buildAuthorizeUrl, loginWithRedirect]);

  useEffect(() => {
    if (!isLoading && (!isAuthenticated || !user)) {
      sendToUniversalLogin();
    }
  }, [isLoading, isAuthenticated, user, sendToUniversalLogin]);

  return (
    <div className={pageStyles.loginPage}>
      <Loader size="medium" color={styles.color.bluePrimary} />
    </div>
  );
};

export const getServerSideProps = withPageAuthSkipped({
  async getServerSideProps() {
    return {
      props: {},
    };
  },
});

export default Login;
